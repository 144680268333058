<template>
  <v-container>
    <v-row>
      <v-col cols="1" class="py-0 pt-2">
        <GoBackBtn :routeToGo="routeToGo" />
      </v-col>
      <v-col cols="11" class="pl-0 pt-0 pb-0">
        <PageHeader
          :title="isEditing ? `${titleEdit}` : titleNew"
          class="py-0"
        />
      </v-col>
    </v-row>
    <v-card>
      <div class="row">
        <v-container class="py-0">
          <v-form v-model="isFormValid">
            <v-container class="py-0">
              <v-row>
                <v-col cols="4" sm="4" md="4" class="pb-0 pr-0">
                  <v-textarea
                    auto-grow
                    rows="1"
                    clearable
                    v-model.trim="nombre"
                    label="Nombre del formato"
                    dense
                    outlined
                    :rules="
                      rules.required.concat([
                        rules.requiredTrim(nombre),
                        rules.maxLength(nombre, 100),
                      ])
                    "
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="4" sm="4" md="4" class="pb-0 pl-3">
                  <v-autocomplete
                    v-model="servidor"
                    label="Servidor"
                    :items="servidores"
                    item-text="value"
                    item-value="id"
                    dense
                    outlined
                    clearable
                    :rules="rules.required"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="4" sm="4" md="4" class="pb-0 pl-0 pr-3">
                  <v-autocomplete
                    v-model="entidadFacturanteSelected"
                    :items="entidadesFacturantes"
                    label="Entidad facturante"
                    item-text="value"
                    item-value="id"
                    outlined
                    clearable
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="4" sm="4" md="4" class="py-0 pr-0">
                  <v-text-field
                    v-model.trim="nombreRemitente"
                    label="Nombre remitente"
                    dense
                    outlined
                    :rules="
                      rules.required.concat([
                        rules.requiredTrim(nombreRemitente),
                        rules.maxLength(nombreRemitente, 100),
                      ])
                    "
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" sm="4" md="4" class="py-0 pl-3">
                  <v-text-field
                    v-model.trim="emailRemitente"
                    label="Email remitente"
                    dense
                    outlined
                    :rules="
                      rules.required.concat([
                        rules.requiredTrim(emailRemitente),
                        rules.maxLength(emailRemitente, 100),
                        rules.validateEmailFormat(emailRemitente),
                      ])
                    "
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" sm="4" md="4" class="pt-0 pb-0 pl-0 pr-3">
                  <v-select
                    outlined
                    clearable
                    dense
                    multiple
                    autocomplete="on"
                    item-text="value"
                    item-value="id"
                    return-object
                    v-model="entidadesFinancierasSelected"
                    label="Entidades financieras"
                    :items="entidadesFinancieras"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="entidadFinancieraToggle()">
                        <v-list-item-action>
                          <v-icon
                            :color="
                              entidadesFinancierasSelected.length > 0
                                ? 'primary'
                                : ''
                            "
                          >
                            {{ multiselectIcon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Todos </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.value }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption">
                        (+{{ entidadesFinancierasSelected.length - 1 }} otros)
                      </span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
            <v-row class="px-3">
              <v-col cols="11" class="py-0">
                <v-text-field
                  v-model="asunto"
                  label="Asunto"
                  dense
                  outlined
                  :rules="
                    rules.required.concat([
                      rules.requiredTrim(asunto),
                      rules.maxLength(asunto, 150),
                    ])
                  "
                >
                </v-text-field>
              </v-col>
              <v-col cols="1" class="d-flex py-0 pl-0 justify-content-center">
                <v-checkbox
                  v-model="activo"
                  id="switch1"
                  class="mt-1"
                ></v-checkbox>
                <label for="switch1" class="mt-2">Activo</label>
                <v-spacer></v-spacer>
              </v-col>
            </v-row>
            <v-row class="px-3">
              <v-col cols="12" class="pt-0">
                <TextEditor
                  ref="text"
                  :value.sync="content"
                  :variables="variables"
                />
              </v-col>
            </v-row>
            <v-container>
              <v-row class="pr-4 pl-4 pt-4" justify="end">
                <v-btn
                  color="primary"
                  :disabled="!isFormValid"
                  :loading="loadingSaveBtn"
                  @click="saveContent"
                >
                  Guardar
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-container>
      </div>
    </v-card>
  </v-container>
</template>
  
  <script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import TextEditor from "@/components/shared/TextEditor.vue";

import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "EditFormatoEmailLoteDeuda",
  components: {
    PageHeader,
    TextEditor,
    GoBackBtn,
  },
  data() {
    return {
      routeToGo: "FormatoEmailLotesDeudas",
      titleNew: enums.titles.NUEVO_FORMATO_EMAIL_LOTE_DEUDA,
      titleEdit: enums.titles.EDITAR_FORMATO_EMAIL_LOTE_DEUDA,
      mSCloseBoxIcon: enums.icons.CLOSE_BOX,
      checkBoxBlankOutlineIcon: enums.icons.CHECKBOX_BLANK_OUTLINE,
      content: "",
      contentChanged: false,
      rules: rules,
      isFormValid: false,
      nombre: null,
      nombreRemitente: null,
      emailRemitente: null,
      servidores: [],
      servidor: null,
      emailId: null,
      asunto: null,
      activo: false,
      codigo: "LOTE",
      entidadesFinancieras: [],
      entidadesFinancierasSelected: [],
      loadingSaveBtn: false,
      entidadFacturanteSelected: null,
      entidadesFacturantes: [],
      variables: [
        {
          nombre: "Periodo",
          variable: "#PERIODO#",
        },
        {
          nombre: "Nombre entidad financiera",
          variable: "#NombreEntidadFinanciera#",
        },
      ],
    };
  },
  computed: {
    multiselectIcon() {
      if (this.selectAllEntidadesFinancieras) return this.mSCloseBoxIcon;
      return this.checkBoxBlankOutlineIcon;
    },
    selectAllEntidadesFinancieras() {
      return (
        this.entidadesFinancierasSelected &&
        this.entidadesFinancierasSelected.length ===
          this.entidadesFinancieras.length
      );
    },
    isEditing() {
      return this.$route.params.formato;
    },
  },
  async created() {
    await this.setSelects();
    if (this.$route.params.formato) {
      this.setFormato();
    }
  },
  methods: {
    ...mapActions({
      fetchServidores: "configuracion/fetchServidores",
      saveEmail: "solicitudesAfiliacion/saveEmail",
      getEntidadesFinancieras: "devengamientos/getEntidadesFinancieras",
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      setAlert: "user/setAlert",
    }),
    async setSelects() {
      const servidores = await this.fetchServidores();
      this.servidores = servidores;
      const entiFinans = await this.getEntidadesFinancieras();
      this.entidadesFinancieras = entiFinans;
      const entFacs = await this.getEntidadesFacturantes();
      this.entidadesFacturantes = entFacs;
      this.entidadesFacturantes.unshift({ id: -1, value: "Todas", label: "" });
    },
    setFormato() {
      const template = this.$route.params.formato;
      this.emailId = template.emailId;
      this.content = template.cuerpoHtml;
      this.nombre = template.nombre;
      this.nombreRemitente = template.nombreRemitente;
      this.emailRemitente = template.emailRemitente;
      this.servidor = template.esId;
      this.asunto = template.asunto;
      if (template.entiFinansId.length > 0) {
        this.entidadesFinancierasSelected = this.entidadesFinancieras.filter(
          (x) => template.entiFinansId.includes(x.id)
        );
      }
      if (template.entFacId === null) this.entidadFacturanteSelected = -1;
      else this.entidadFacturanteSelected = template.entFacId;
      this.activo = template.activo;
    },
    async saveContent() {
      let res = null;
      if (this.content.trim() === null || this.content.trim() === "") {
        this.setAlert({
          type: "warning",
          message: "El cuerpo del email está vacío.",
        });
      } else {
        this.loadingSaveBtn = true;

        // Se achica el interlineado para la impresion
        // TODO: buscar los nodos p en el html, ya que si tiene algun style ya aplicado no va a encontrar el tag <p>
        this.content = this.content.replaceAll(/<p><\/p>/g, "<p><br /></p>");
        this.content = this.content.replaceAll(
          /<p>/g,
          '<p style="margin: 2px 0px">'
        );

        const data = {
          emailId: this.emailId,
          nombre: this.nombre,
          nombreRemitente: this.nombreRemitente,
          emailRemitente: this.emailRemitente,
          codigo: this.codigo,
          esId: this.servidor,
          asunto: this.asunto,
          entFacId:
            this.entidadFacturanteSelected === -1
              ? null
              : this.entidadFacturanteSelected,
          activo: this.activo,
          cuerpoHtml: this.content,
          entiFinansId: this.entidadesFinancierasSelected.map((x) => x.id),
        };
        try {
          res = await this.saveEmail(data);
          if (res.status === 200) {
            this.setAlert({ type: "success", message: "Guardado con éxito" });
            this.$router.push({ name: this.routeToGo });
          }
        } catch (error) {
          this.loadingSaveBtn = false;
        }
      }
    },
    entidadFinancieraToggle() {
      this.$nextTick(() => {
        if (this.selectAllEntidadesFinancieras)
          this.entidadesFinancierasSelected = [];
        else this.entidadesFinancierasSelected = this.entidadesFinancieras;
      });
    },
  },
};
</script>
  <style lang="scss" scoped></style>
  